<template>
    <LayoutNext>
        <template #page-title>
            Create User Account
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/users">Users</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Create User Account
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
            </div>
        </div>
        <MDBCard id="UserInformation" class="shadow account-form  m-auto" tag="form" @submit.prevent="submit">
            <MDBCardHeader class="py-3">
                <h5 class="fw-bold mb-0">Account Information</h5>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column" style="gap: 15px">
                <div>
                    Email:
                    <MDBInput class="mt-1" type="email" v-model="form.email" />
                </div>
                <div>
                    Partner Percentage:
                    <MDBInput class="mt-1" type="number" placeholder="Partner Percentage" v-model="partnerPercentage" />
                </div>
                <div>
                    CS Code:
                    <MDBInput class="mt-1" type="text" placeholder="CS Code" v-model="form.csCode" />
                </div>
                <div class="mt-1">
                    <MDBSwitch label="Is Distributor" v-model="form.isDistributor" />
                </div>
                <div v-if="form.isDistributor">
                    Google Sheet Info:
                    <MDBInput label="Google Sheet Link" type="text" v-model="form.googleSheetLink" class="mt-4" />
                    <MDBInput label="Google Sheet Name" type="text" v-model="form.sheetName" class="mt-4" />
                </div>
                <div class="mt-2">
                    <MDBBtn class="fw-bold" type="button" color="primary" aria-controls="assetLabelModal"
                        @click="assetLabelModal = true" size="sm">
                        Select Asset Label/s
                    </MDBBtn>
                    <MDBTable hover class="mt-3">
                        <thead class="table-light align-middle">
                            <tr>
                                <th v-for="(item, i) in tableHeaders" :key="i" scope="col" class="py-3 px-4">
                                    {{ item.header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="selectedAssetLabelsArray.length < 1">
                            <tr class="align-middle">
                                <td colspan="3">
                                    No asset label selected.
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="selectedAssetLabelsArray.length > 0">
                            <tr v-for="(item, i) in selectedAssetLabelsArray" :key="i" class="align-middle">
                                <td class="align-middle">{{ item.name }}</td>
                                <td class="align-middle" style="width: 45%;">
                                    <select v-model="item.assignedDashboard" class="form-select">
                                        <option v-for="option in dashboard" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </td>
                                <td class="d-flex justify-content-end text-right p-4">
                                    <MDBBtn color="danger" floating @click.stop.prevent="clickDelete(item)" size="sm">
                                        <em class="far fa-trash-alt" />
                                    </MDBBtn>
                                </td>
                            </tr>
                        </tbody>
                    </MDBTable>
                </div>
            </MDBCardBody>
            <MDBCardFooter class="py-3 px-4 d-flex justify-content-end">
                <MDBBtn class="fw-bold" type="submit" color="primary" :disabled="isInviteBtnDisabled" size="sm">
                    Invite User
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
        <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabelModalLabel" v-model="assetLabelModal"
            scrollable :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="assetLabelModalLabel"> Asset Labels </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="pb-0">
                <div>
                    <MDBInput label="Seach Asset Label" class="mb-4" v-model="dataTableSearch" />
                    <MDBDatatable :dataset="assetLabelDataTable" selectable multi :search="dataTableSearch"
                        @selected-rows="handleSelectedRow($event)" hover />
                </div>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="assetLabelModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="assetLabelModal = false">
                    Select Asset Labels
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <DeductionsToast />
    </LayoutNext>
</template>

<script setup>
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBModalFooter, MDBDatatable, MDBInput, MDBSwitch, MDBTable, MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { useRouter } from "vue-router";
import { computed, ref, reactive, watch, onBeforeMount } from "vue";
import axios from "axios";
import { PostInviteUser } from "@/services/Users/PostInviteUser";
import DeductionsToast from "@/components/Deductions/DeductionsToast.vue";
import { useToastStore } from "@/store/toastStore";
import { useTitle } from "@vueuse/core";

useTitle("Create User Account | CreatorShield");

const router = useRouter();
const assetLabelModal = ref(false);
const toastStore = useToastStore();

const assetLabelDataTable = ref({
    columns: [{ label: "Name", field: "name" }],
    rows: [],
});

const tableLoader = ref(false);
const dataTableSearch = ref("");
const partnerPercentage = ref(0);

onBeforeMount(async () => {
    assetLabelDataTable.value.rows = [];
    tableLoader.value = true;
    await axios.get("api/assetLabels/Dropdown").then(function (data) {
        assetLabelDataTable.value.rows = data.data;
        tableLoader.value = false;
    });
});

const handleSelectedRow = (selectedModalArray) => {
    const newArray = selectedModalArray.map(label => ({
        ...label,
        assignedDashboard: label.assignedDashboard || 1,
    }));
    selectedAssetLabelsArray.value = newArray;
};

const clickDelete = (item) => {
    const newArray = selectedAssetLabelsArray.value.filter(
        (element) => element.id != item.id
    );
    selectedAssetLabelsArray.value = newArray;
};

let loadingSpinner = ref(false);

const selectedAssetLabels = ref("");
const selectedAssetLabelsArray = ref([]);
watch([selectedAssetLabels], ([newSelectedAssetLabels]) => {
    selectedAssetLabelsArray.value = newSelectedAssetLabels.split(",");
});

const form = reactive({
    email: "",
    isDistributor: false,
    link: null,
    googleSheetLink: "",
    sheetName: "",
    assignedDashboard: 1,
    csCode: "",
});

const tableHeaders = computed(() => [
    {
        header: "Name",
    },
    {
        header: "Dashboard",
    },
    {},
]);

const isInviteBtnDisabled = computed(
    () => form.email == "" || partnerPercentage.value == ""
);

const submit = async () => {
    const formData = {
        email: form.email,
        userTypeId: getUserTypeId.value,
        googleSheetLink: form.isDistributor ? form.googleSheetLink : null,
        assignedAssetLabels: selectedAssetLabelsArray.value.map(label => ({
            id: label.id,
            assignedDashboard: label.assignedDashboard,
        })),
        partnerPercentage: partnerPercentage.value,
        sheetName: form.isDistributor ? form.sheetName : null,
        csCode: form.csCode,
    };

    loadingSpinner.value = true;
    const toastSuccess = {
        ...toastStore.toastStatus.success,
        ...{
            message: "Successfully invited!",
        },
    };
    await PostInviteUser(formData)
        .then(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
        })
        .catch((response) => {
            if (response.status != 200 || response.status != 201) {
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.fail,
                };
            }
        })
        .finally(() => {
            loadingSpinner.value = false;
            router.push({ name: "UserManagement" });
        });
};

const getUserTypeId = computed(() => (form.isDistributor ? 3 : 2));

const dashboard = ref([
    { text: 'CreatorShield & VeryViralVisuals', value: 1 },
    { text: 'CreatorShield', value: 2 },
    { text: 'VeryViralVisuals', value: 3 },
]);
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-secondary {
    background-color: var(--accent);
}

.card,
.card-body {
    overflow-y: auto;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-form {
    width: 70%
}

@media screen and (max-width: 767px) {
    .account-form {
        width: 100%
    }
}
</style>